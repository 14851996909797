<template lang="html">
  <div :class="{'error-border':error}">
    <VueFileAgent
      ref="vueFileAgent"
      multiple
      deletable
      meta
      :helpText="helpText"
      theme="list"
      maxSize="5MB"

      :maxFiles="14"
      :errorText="{
        size: 'File tidak boleh lebih dari 5mb',
      }"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      @upload="uploadEvent('upload', $event)"
      @upload:error="uploadEvent('upload:error', $event)"
      @upload:delete="uploadEvent('upload:delete', $event)"
      @upload:delete:error="uploadEvent('upload:delete:error', $event)"
      @upload:update="uploadEvent('upload:update', $event)"
      @upload:update:error="uploadEvent('upload:update:error', $event)"
      v-model="fileRecords"
    ></VueFileAgent>
    <v-dialog v-model="confirmDialog" max-width="400px">
      <v-card>
        <v-card-text>Apakah anda yakin untuk menghapus file ini?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" @click="confirmDelete()">Ya</v-btn>
          <v-btn @click="confirmDialog=false">Tidak</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p class="red--text mx-2" v-if="error">{{errorText}}</p>
    <!-- <v-btn @click="uploadFiles()" small color="primary">upload</v-btn> -->
  </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import store from '@/store'
import VueFileAgent from 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'

Vue.use(VueFileAgent)

export default {
  props:{
    uploadUrl:{
      default:'https://mangbagja.bandung.go.id/api/skp-triwulan-upload-evidence',
      type:String
    },
    errorInput:{
      default:false,
      type:Boolean
    },
    errorText:{
      default:'Tidak boleh kosong',
      type:String
    },
    helpText:{
      default:'Evidence / Bukti Laporan',
      type:String
    }
  },

  data(){
    return{
      confirmDialog:false,
      fileRecords:[],
      uploadHeaders: { 'x-access-token': store.getters['user/token'] },
      fileRecordsForUpload: [], // maintain an upload queue
      deleteFileRecord:{}
    }
  },

  watch:{
    fileRecordsForUpload(val){
      if (val.length > 0){
        this.uploadFiles()
      }
    }
  },

  computed:{
    error(){
      if (!this.errorInput || this.fileRecords.length > 0){
        return false
      }
      return true
    },
  },

  methods:{
    clearAll(){
      this.fileRecords = []
      this.fileRecordsForUpload = []
      this.deleteFileRecord = {}
    },

    uploadEvent(eventName, data) {
      console.log('UPLOAD EVENT ', eventName, data)
      _.each(data,(value,key)=>{
        if(value.data.return == '0'){
          store.commit('snackbar/setSnack',{message: 'Mohon maaf, input data sudah ditutup.', color:'warning'})
        }else{
          let data = value.data.data[0]
          console.log(data.success)
          if(value.data.response == 'success'){
            console.log(data.file)
            this.$emit('fileUploaded', {name:data.file})
            store.commit('snackbar/setSnack',{message: value.data.message, color:'success'})
          }else{
            store.commit('snackbar/setSnack',{message: value.data.message, color:'error'})
          }
        }
      })
    },

    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      console.log('upload files', this.fileRecords, this.jenis)
      // SKPService.uploadSKPEvidence(this.fileRecords, this.jenis).then(response=>{
      //   let res = response.data
      //   console.log(res)
      // })
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload, function createFormData(fileData){
        var formData = new FormData()
        formData.append('nip', store.state.user.current.username)
        formData.append('file[0]', fileData.file)
        formData.append('token', store.getters['user/token'])
        return formData
      })
      // .then(response=>{
      //   let res = response.data
      //   console.log(res)
      //   this.$emit('fileUploaded', response.data.file)
      //   store.commit('snackbar/setSnack',{message:'Data berhasil di upload', color:'success'})
      // });
      //this.$emit('fileUploaded', this.fileRecordsForUpload)

      this.fileRecordsForUpload = [];
    },

    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      console.log('delete upload', fileRecord)
      this.$emit('delFileUploaded', fileRecord)
      // this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },

    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },

    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
      // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        this.deleteFileRecord = fileRecord
        this.confirmDialog = true
      }
    },

    confirmDelete(){
      this.$refs.vueFileAgent.deleteFileRecord(this.deleteFileRecord); // will trigger 'delete' event
      this.confirmDialog = false
    },

    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  }
}
</script>

<style lang="css" scoped>
.error-border{
  border: 2px dashed red;
}
</style>
