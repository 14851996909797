<template>
<v-container fluid>
  <v-row>
    <v-col>
      <base-material-card icon="mdi-account-group">
        <template v-slot:heading>
          <h3 class="display-2">Input Dokumen Laporan</h3>
        </template>

        <crud
          :crudSettings="crudSettings"
          :headers="headersInputDokumenLaporan"
          @onTableChange="updateTableInputDokumenLaporan"
          :responseData="itemsLaporan"
          @onEditClick="editItem"
          @onDeleteClick="deleteItem"
          :dataLoading="loadingLaporan"
        >

        <template v-slot:append-header>
          <v-col cols="9" >
            <v-select
              class="float-left"
              item-text="b"
              item-value='k'
              :items="bulanList"
              label="Pilih Bulan"
              v-model="selectedBulan"
              dense
              @change="filterBulan"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn color="green" @click="createItem()" small dark class="mx-1"><v-icon small left>mdi-plus</v-icon> Tambah Dokumen Laporan</v-btn>
          </v-col>
        </template>

        </crud>

      </base-material-card>
    </v-col>
  </v-row>

  <!-- The following below is the form for adding review LHP report -->
  <v-dialog v-model="dialogTambahLaporan" max-width="600px">
    <v-card>
      <v-card-text>
        <v-form ref="formTambah">
          <h1 class="display-2 my-2">Input Dokumen Laporan</h1>

          <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
            transition="scale-transition"
            lazy offset-y full-width>
            <template v-slot:activator="{ on }">
              <!-- :value="fromDateDisp" -->
              <v-text-field label="Tanggal" :rules="[rules.required]" append-icon="mdi-calendar" outlined dense readonly v-model="fromDateVal" v-on="on"></v-text-field>
            </template>
            <v-date-picker
            locale="in-ID"
            :min="getStartDate" :max="getEndDate"
            v-model="fromDateVal"
            no-title
            @input="fromDateMenu = false"
            >
            </v-date-picker>
          </v-menu>

          <v-text-field v-model="formEdit.uraian" :rules="[rules.required]" dense label="Uraian" outlined ></v-text-field>

          <v-autocomplete dense v-model="formEdit.bulan" :items="bulanList" :rules="[rules.required]" item-text="b" item-value="k" label="Bulan" outlined ></v-autocomplete>

          <v-autocomplete dense v-model="formEdit.triwulan" :items="[1,2,3,4]" :rules="[rules.required]" label="Triwulan" outlined ></v-autocomplete>

          <v-text-field v-model="formEdit.keterangan" :rules="[rules.required]" dense outlined label="Keterangan"></v-text-field>

          <file-input :helpText="'Evidence / Lampiran Dokumen Laporan'" ref="fileInput" :errorInput="fileInputErrorFlag" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile"></file-input>
          <!-- <file-input :helpText="'File'" ref="fileInput" :errorInput="fileInputErrorFlag" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile"></file-input> -->

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="tutup()">Tutup</v-btn>
        <v-btn color="success" :loading="simpanDataLoading" @click="simpanItem()">Simpan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import Crud from '@/components/common/Crud'
// import InputDokumenLaporanService from '@/services/InputDokumenLaporanService'
import FileInput from '@/components/common/FileInputPPNS'
import {defaultRules} from '@/utils/lib'
import axios from 'axios'
import _g from '../../global'

export default {
  components:{
    Crud,
    FileInput
  },

  data(){
    return{
      fromDateMenu: false,
      fromDateVal: null,
      date: new Date(), 
      dialogTambahLaporan:false,
      fileInputErrorFlag:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        deleteTitleKey:'no_surat',
        enableHeader:false,
        enableDetail:false,
        enableCreate:false,
        enableEdit:true,
        enableDelete:true,
      },
      listSKPD: [],
      listHari: [
        {text:"-", value:0},
        {text:'Senin', value:1},
        {text:'Selasa', value:2},
        {text:'Rabu', value:3},
        {text:'Kamis', value:4},
        {text:'Jumat', value:5},
        {text:'Sabtu', value:6},
        {text:'Minggu',value:7}
      ],
      selectedJenisLHP: null,
      headersInputDokumenLaporan:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Tanggal', value:'tanggal', sortable:false, class:'header-index-profile'},
        {text:'Bulan', value:'bulan', sortable:false, class:'header-index-profile'},
        {text:'Triwulan', value:'triwulan', sortable:false, class:'header-index-profile'},
        {text:'Uraian', value:'uraian', sortable:false, class:'header-index-profile'},
        {text:'Keterangan', value:'keterangan', sortable:false, class:'header-index-profile'},
        {text:'Evidence/Bukti', value:'file_', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      formEdit:{file:[],},
      bulanList:[
        { b:'Januari',k:1},
        { b:'Febuari',k:2},
        { b:'Maret',k:3},
        { b:'April', k:4},
        { b:'Mei', k:5},
        { b:'Juni', k:6},
        { b:'Juli', k:7},
        { b:'Agustus', k:8},
        { b:'September', k:9},
        { b:'Oktober', k:10},
        { b:'November', k:11},
        { b:'Desember', k:12},
      ],
      itemsLaporan: {},
      showItem:false,
      rules:{
        ...defaultRules
      },
      allowed: null,
      selectedBulan: 1,
      loadingLaporan: false,
      simpanDataLoading: false
    }
  },
  computed:{
    fromDateDisp() {
      return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    getStartDate() {
      let startDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        startDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth()+1, 1)
      }else{
        startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
      }
      return startDate.getFullYear()+'-'+this.addZero(startDate.getMonth())+'-'+this.addZero(startDate.getDate())
    },
    getEndDate() {
      let endDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        let last_date = new Date(tanggal_.getFullYear(), tanggal_.getMonth() + 1, 0)
        endDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth(), last_date.getDate())
      }else{
        endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
      }
      return endDate.getFullYear()+'-'+this.addZero(endDate.getMonth()+1)+'-'+this.addZero(endDate.getDate())
    },
    uploadUrl(){
      return process.env.VUE_APP_API_URL + '/api/mb-upload/setwan/'
    },
    parseFile(){
      if(!this.itemsLaporan.foto){
        return '-'
      }else{
        return JSON.parse(this.itemsLaporan.foto)
      }
    }
  },

  methods:{
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    addFormFile(file){
      this.formEdit.file.push(file.name)
      console.log("added_file",this.formEdit.file)
    },
    delFormFile(file){
      let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/
      console.log("file_", file.file != undefined ? file.file.name : file)
      let filename = file.name != undefined ? file.file.name : file
      this.formEdit.foto = this.formEdit.file.filter((val)=>{
        const match = val.match(regex)
        let curr_filename = match[1]+'.'+match[2]
        if (match){
          return filename != curr_filename
        }
        return false
      })
    },
    parseFilename(filename){
      let parsedFilename = /\d+_(.*)-[\w\d]+(\.\w+)/g.exec(filename)
      try{
        return parsedFilename[1] + parsedFilename[2]
      } catch(e){
        return filename
      }
    },
    filterBulan(bulan_){
      console.log("bulan",bulan_)
      let bulan = bulan_
      if(bulan == undefined){
        let tanggal_ = new Date
        bulan = tanggal_.getMonth()+1
      }
      this.selectedBulan = bulan
      this.updateTableInputDokumenLaporan(bulan)
    },
    simpanItem(){
      this.simpanDataLoading = true
      this.formEdit.tanggal = this.fromDateVal
      if(this.formEdit.file != undefined && this.formEdit.file != null && this.formEdit.file.length != null){
        if(this.formEdit.file.length > 0){
          if(this.formEdit.file.length > 0 && this.formEdit.file.length < 5){
            let _join = ""
            for (let i = 0; i < this.formEdit.file.length; i++) {
              _join += this.formEdit.file[i].replace(/[\[\]']+/g,'')+"," /* eslint-disable-line */
            }
            this.formEdit.file = "[" + _join.replace(/,(?=\s*$)/, '') + "]" //remove last comma
          }
        }else{
          return store.commit('snackbar/setSnack',{message: "Maaf, Anda Belum Melampirkan File.", color:'error'})
        }
      }else{
        return store.commit('snackbar/setSnack',{message: "Maaf, Anda Belum Melampirkan File Evidence/Lampiran Dokumen Laporan.", color:'error'})
      }

      // if(this.editState == true){
      //   InputDokumenLaporanService.updateLaporan(this.formEdit, this.formEdit.id).then(response => {
      //     // let res = response.data
      //     if (response.status){
      //       store.commit('snackbar/setSnack',{message: 'Laporan Dokumen Laporan Berhasil Diubah.', color:'success'})
      //       this.editState = false
      //       this.dialogTambahLaporan = false
      //       this.$refs.fileInput.clearAll()
      //       this.updateTableInputDokumenLaporan()
      //     } else {
      //       this.editState = false
      //       store.commit('snackbar/setSnack',{message: response.message, color:'error'})
      //     }
      //   })
      // }else{
        var current = this.$store.getters["user/current"]
        var base_url_api = _g.getBaseURLAPIERK(current.year)
        const url_store_data = base_url_api + 'v1/store-laporan-kabag-setwan'
        axios.post(url_store_data, {
          nip: current.username,
          tanggal: this.formEdit.tanggal,
          uraian: this.formEdit.uraian,
          bulan: this.formEdit.bulan,
          triwulan: this.formEdit.triwulan,
          file: this.formEdit.file,
          // file: "[\"197507152007012015_lph_202302281135361489969750.png\"]",
          keterangan: this.formEdit.keterangan,
          api: 1,
          id: this.editState ? this.formEdit.id : null
        }).then(response => {
          console.log('get-data-dokumen-lhp',response.data)
          if(response.status){
            store.commit('snackbar/setSnack',{message: 'Berhasil Melaporkan Dokumen Laporan.', color:'success'})
            this.dialogTambahLaporan = false
            this.$refs.fileInput.clearAll()
            this.updateTableInputDokumenLaporan(this.selectedBulan)
          }else{
            store.commit('snackbar/setSnack',{message: response.data.error, color:'error'})
          }
          this.simpanDataLoading = false
        }).catch(err => {
          var error = err.message
          store.commit('snackbar/setSnack',{message: error, color:'error'})
          this.simpanDataLoading = false
        })
        // InputDokumenLaporanService.storeLaporan(this.formEdit).then(response => {
        //   // let res = response.data
        //   if (response.status){
        //     store.commit('snackbar/setSnack',{message: 'Berhasil Melaporkan Dokumen Laporan.', color:'success'})
        //     this.dialogTambahLaporan = false
        //     this.$refs.fileInput.clearAll()
        //     this.updateTableInputDokumenLaporan()
        //   } else {
        //     store.commit('snackbar/setSnack',{message: response.message, color:'error'})
        //   }
        // })
        // }
      // }
    },
    updateTableInputDokumenLaporan(bulan_){
      if(bulan_ == undefined){
        bulan_ = (new Date()).getMonth()+1
      }
      this.selectedBulan = bulan_
      console.log("bulan",bulan_)
      this.forceUpdate = false
      // this.tableValueTahunan = val ? val: this.tableValueTahunan
      this.loadingLaporan = true
      this.itemsLaporan = {}

      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-data-laporan-kabag-setwan'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: bulan_,
        api: 1
      }).then(response => {
        console.log('get-data-dokumen-lhp',response.data)
        if(response.data.status){
          // response.data.data.forEach(el => {
          //   el.tanggal_ = el.tanggal
          // });
          this.itemsLaporan = response.data
          this.allowed = response.data.allowed
          console.log(this.itemsLaporan)
        }else{
          store.commit('snackbar/setSnack',{message: response.data.error, color:'error'})
        }
        this.loadingLaporan = false
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })

      // InputDokumenLaporanService.getLaporan({
      //   bulan:bulan_
      //   })
      // .then((response)=>{
      //   console.log('tahunan',response.data)
      //   response.data.data.forEach(el => {
      //     el.tanggal_ = this.getFullDateDay(el.tanggal)
      //   });
      //   this.itemsLaporan = response.data
      //   this.allowed = response.allowed
      //   console.log(this.itemsLaporan)
      // }).finally(()=>{
      //   this.loadingLaporan = false
      // })
    },

    getFullDateDay(tanggal = 0) {
        let tanggal_ = new Date(tanggal+'T00:00:00')
        return tanggal_.getDate()+" "+this.bulanList[tanggal_.getMonth()].b+" "+tanggal_.getFullYear();
    },

    clearForm(){
      this.formEdit = {}
      this.fromDateVal = null
      this.selectedJenisLHP = null
      this.formEdit = {}
      this.formEdit.file=[]
      this.editState = false
      this.showItem = false
    },

    createItem(){
      this.clearForm()
      this.dialogTambahLaporan=true
      if (this.$refs.fileInput){
        this.$refs.fileInput.clearAll()
      }
      // this.formTambah.file=[]
      // this.$refs.formTambah.resetValidation()
      setTimeout(()=>{
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      },500)
    },
    editItem(item){
      if (this.$refs.fileInput){
        this.$refs.fileInput.clearAll()
      }
      this.formEdit = item; this.dialogTambahLaporan = true; this.formEdit.file=[]
      // let tanggal = this.formEdit.tanggal.substring(8,10); let bulan = this.formEdit.tanggal.substring(5,7); let tahun = this.formEdit.tanggal.substring(0,4)
      // let d = new Date(); let h = this.addZero(d.getHours()); let m = this.addZero(d.getMinutes()); //let s = this.addZero(d.getSeconds());
      // this.formEdit.tanggal = tanggal + "/" + bulan + "/" + tahun + " " + h + ":" + m
      // this.formEdit.tanggal = this.formEdit.tanggal + "T" + h + ":" + m
      // this.formEdit.tanggal = this.formEdit.tanggal + " " + h + ":" + m
      this.formEdit = _.clone(this.formEdit)
      this.formEdit.bulan = parseInt(item.bulan)
      this.formEdit.triwulan = parseInt(item.triwulan)
      this.fromDateVal = this.formEdit.tanggal
      this.selectedJenisLHP = this.formEdit.jenis
      this.editState = true
      setTimeout(()=>{
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      },500)
    },
    deleteItem(item){
      this.formEdit = item
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_del_data = base_url_api + 'v1/hapus-laporan-kabag-setwan'
      axios.post(url_del_data, {
        nip: current.username,
        id: this.formEdit.id,
        api: 1
      }).then(response => {
        if (response.data.success){
          store.commit('snackbar/setSnack',{message: 'Data Dokumen Laporan Berhasil Dihapus!', color:'success'})
          this.updateTableInputDokumenLaporan(this.selectedBulan)
        }else {
          store.commit('snackbar/setSnack',{message: response.data.message, color:'error'})
        }
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
      // InputDokumenLaporanService.hapusLaporan(this.formEdit).then((response)=>{
      //   if (response.status){
      //     store.commit('snackbar/setSnack',{message: 'Data Dokumen Laporan Berhasil Dihapus!', color:'success'})
      //     this.updateTableInputDokumenLaporan()
      //   }else {
      //     store.commit('snackbar/setSnack',{message: response.message, color:'error'})
      //   }
      // })
    },
    tutup(){
      this.dialogTambahLaporan=false
      this.editState = false
      return this.updateTableInputDokumenLaporan(this.selectedBulan)
    }

  },

  created(){
    this.allowed = null
    this.updateTableInputDokumenLaporan()
    this.filterBulan()
  },
}
</script>

<style lang="css" scoped>
.label{
  font-size:12px
}
</style>
